@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: "Montserrat", sans-serif;
    }
}
/* Theme-switch customize */
.theme-switch .react-switch-handle {
    transition: background-color 0.2s;
}
.theme-switch .react-switch-handle:hover {
    box-shadow: 0 0 0 1px #9ca3af !important;
}
.theme-switch .react-switch-handle:active {
    background: #e5e7eb !important;
}
.theme-switch .react-switch-handle:focus {
    box-shadow: 0 2px 5px 0 rgba(6, 25, 56, 0.13) !important;
}

[type='text']:focus, input:where(:not([type])):focus, [type='email']:focus, [type='url']:focus, [type='password']:focus,
[type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus,
[type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
    outline: none;
    box-shadow: none;
}

.__floater__arrow {
    display: none !important;
}


