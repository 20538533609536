.date-time-field .react-datepicker {
    font-family: "Montserrat", sans-serif;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    box-shadow: 0 0 0 1px #9ca3af;
}

.date-time-field .react-datepicker__day--selected {
    border: 2px solid #daf3b7;
    background: #8dc63f;
}

.date-time-field .react-datepicker__day--selected:hover {
    border: 2px solid #daf3b7;
    background: #659622;
}

.date-time-field .react-datepicker__header.react-datepicker__header--time {
    padding-top: 20px;
    padding-bottom: 20px;
}

.date-time-field .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background: #8dc63f;
}

.dark .date-time-field .react-datepicker,
.dark .date-time-field .react-datepicker__header,
.dark .date-time-field .react-datepicker__time-container .react-datepicker__time{
    background: #2B3544;
}

.dark .date-time-field .react-datepicker__current-month,
.dark .date-time-field .react-datepicker-time__header {
    color: #ffffff;
}

.dark .date-time-field .react-datepicker__day-name {
    color: #f6f5f3;
}

.dark .date-time-field .react-datepicker__day,
.dark .date-time-field .react-datepicker__time-list {
    color: #f9fafb;
}

.dark .date-time-field .react-datepicker__day:hover,
.dark .date-time-field .react-datepicker__day--keyboard-selected,
.dark .date-time-field .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover{
    background: #8DC63F40;
    color: #8DC63F;
}

.dark .date-time-field .react-datepicker__day--disabled {
    color: #d1d5db;
}

.dark .date-time-field .react-datepicker__day--disabled:hover {
    background: transparent;
    color: #d1d5db;
}
